<template>
  <div :class="$style.home">
    <BjSearch @refresh="getList" @init="init">
      <BjInput
        v-model="search.keyword"
        :class="$style.input"
        v-bind="layout"
        label="搜索账号"
        :placeholder="
          search.search_type === 1
            ? '请输入账号姓名'
            : search.search_type === 2
            ? '请输入账号绑定邮箱'
            : '请输入账号绑定手机'
        "
        @pressEnter="getList"
      >
        <a-select slot="addonBefore" v-model="search.search_type" style="width: 100px">
          <a-select-option :value="1"> 账号姓名 </a-select-option>
          <a-select-option :value="2"> 绑定邮箱 </a-select-option>
          <a-select-option :value="3"> 绑定手机 </a-select-option>
          <i slot="suffixIcon" class="ri-arrow-down-s-line suffix" />
        </a-select>
      </BjInput>
      <BjSelect
        v-model="search.role_id"
        v-bind="layout"
        key-field="id"
        label="所属角色"
        inner-search
        placeholder="请选择"
        value-field="id"
        label-field="name"
        show-all
        :options="roleData"
      />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="账号状态"
        placeholder="请选择"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
    </BjSearch>
    <div :class="$style.table">
      <a-row>
        <a-col span="12" :class="$style.title"> 账号列表 </a-col>
        <a-col span="12" class="text-right">
          <BjButton v-permission="'system.permission.account-create'" type="primary" @click="onAdd()">
            <i class="ri-add-fill" />
            添加账号
          </BjButton>
        </a-col>
      </a-row>
      <a-table class="mt-20" :columns="columns" :loading="loading" :data-source="data" :pagination="false" row-key="id">
        <template #avatar="item">
          <div :class="$style.avatar">
            <img :src="item.avatar" />
          </div>
        </template>
        <template #action="item">
          <div v-if="!item.is_master">
            <bj-link v-permission="'system.permission.account-edit'" type="primary" @click="onEdit(item)">
              编辑
            </bj-link>
            <a-popconfirm title="确定要删除账号吗？" placement="topLeft" @confirm="onDelete(item)">
              <bj-link v-permission="'system.permission.account-delete'" type="danger"> 删除 </bj-link>
            </a-popconfirm>
          </div>
          <div v-else>-</div>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>

    <bj-modal
      title="编辑账号"
      :class="$style.modal"
      :body-style="{ 'min-height': '400px' }"
      :visible="visible.edit"
      @ok="editOk()"
      @cancel="editCancel()"
    >
      <div :class="$style.tip">
        管理员账号的资料信息请对应管理员登录文化云后，在“账号信息”模块修改，这里仅可设置管理员对应的角色和状态。
      </div>
      <BjForm>
        <BjValidationItem rules="required" label-align="left" label="所属角色" v-bind="layout">
          <BjSelect
            v-model="edit.role_id"
            key-field="id"
            placeholder="请选择"
            value-field="id"
            label-field="name"
            show-all
            :options="roleSelectData"
          />
        </BjValidationItem>
        <BjValidationItem class="mt-20" rules="required" label-align="left" label="账号状态" v-bind="layout">
          <BjSwitch v-model="edit.status" size="default" />
        </BjValidationItem>
      </BjForm>
    </bj-modal>

    <bj-modal
      title="添加账号"
      :class="$style.modal"
      :body-style="{ 'min-height': '400px' }"
      :visible="visible.add"
      @cancel="cancel()"
    >
      <div :class="$style.step">
        <a-steps :current="step" size="small">
          <a-step title="输入账号" />
          <a-step title="账号信息" />
          <a-step title="设置权限" />
        </a-steps>
      </div>
      <BjForm v-if="step === 0">
        <BjInput
          v-model="add.username"
          v-bind="layout"
          label="登录账号"
          label-align="left"
          rules="required"
          placeholder="请输入登录账号（手机号码或邮箱）"
        />
      </BjForm>
      <BjForm v-if="step === 1 && add.check">
        <div :class="$style.tip">
          你输入的账号在系统中不存在，请按照以下表单完善账号信息，全部步骤完善后，管理员可通过此账号登录当前文化云。
        </div>
        <BjInput
          v-model="add.valid_code"
          v-bind="layout"
          rules="required"
          label="验证信息"
          label-align="left"
          placeholder="请输入上一步手机号码或邮箱收到的验证码"
        >
          <span v-if="!finish" slot="suffix" class="tag cursor" @click="getCode()">{{
            time === 0 ? '没收到？重新发送' : '获取验证码'
          }}</span>
          <span v-else slot="suffix" class="tag">{{ time }}秒后可重新获取</span>
        </BjInput>
        <a-row class="mt-20">
          <a-col span="6">
            <span class="required">*</span>
            账号头像
          </a-col>
          <a-col span="18">
            <uploadAvatar :src="add.avatar" @complete="avatarChange" />
            <p class="tip mt-10 mb-0">账号头像建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</p>
          </a-col>
        </a-row>
        <BjInput
          v-model="add.real_name"
          class="mt-20"
          v-bind="layout"
          rules="required"
          label="账号姓名"
          label-align="left"
          placeholder="请输入账号的用户姓名"
        />
        <BjInput
          v-model="add.password"
          class="mt-20"
          v-bind="layout"
          rules="required"
          label="登录密码"
          label-align="left"
          placeholder="请输入账号的登录密码"
          autocomplete="new-password"
          :type="passwordType"
          @keydown.native="keydown($event)"
          @keyup="add.password = add.password.replace(/[\u4e00-\u9fa5]/g, '')"
        >
          <span slot="suffix" @click="passwordChange()">
            <i
              class="ri-eye-line primary"
              :style="passwordType === 'password' ? 'color: #5c5c5c' : ''"
              :class="$style.icon"
            />
          </span>
        </BjInput>
        <a-row class="mt-20">
          <a-col span="6"> 描述信息 </a-col>
          <a-col span="18">
            <a-textarea
              v-model="add.describe"
              :auto-size="{ minRows: 3, maxRows: 3 }"
              placeholder="请输入此账号的描述信息。"
              :max-length="200"
            />
          </a-col>
        </a-row>
      </BjForm>
      <div v-if="step === 1 && !add.check">
        <div :class="$style.tip">
          你输入的账号在系统中已存在，请确认以下账号信息，全部步骤完善后，管理员可通过此账号登录当前文化云。
        </div>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            登录账号
          </a-col>
          <a-col span="18">{{ add.username }}</a-col>
        </a-row>
        <a-row class="mt-20">
          <a-col span="6">
            <span class="required">*</span>
            账号姓名
          </a-col>
          <a-col span="18">{{ info.real_name }}</a-col>
        </a-row>
      </div>
      <BjForm v-if="step === 2">
        <BjValidationItem rules="required" label-align="left" label="所属角色" v-bind="layout">
          <BjSelect
            v-model="add.role_id"
            key-field="id"
            placeholder="请选择所属角色"
            value-field="id"
            label-field="name"
            show-all
            :options="roleSelectData"
          />
        </BjValidationItem>
        <BjValidationItem class="mt-20" rules="required" label-align="left" label="账号状态" v-bind="layout">
          <BjSwitch v-model="add.status" size="default" />
        </BjValidationItem>
      </BjForm>
      <template #footer>
        <div v-if="step === 0">
          <BjButton type="primary" @click="onNext(1)"> 下一步 </BjButton>
          <BjButton @click="cancel()"> 取消 </BjButton>
        </div>
        <div v-if="step === 1">
          <BjButton type="primary" @click="onNext(2)"> 下一步 </BjButton>
          <BjButton @click="cancel()"> 取消 </BjButton>
        </div>
        <div v-if="step === 2">
          <BjButton type="primary" @click="onCreate()"> 确定 </BjButton>
          <BjButton @click="cancel()"> 取消 </BjButton>
        </div>
      </template>
    </bj-modal>
  </div>
</template>

<script>
import uploadAvatar from '@/components/uploadAvatar'
import { roleService, systemInfoService, systemService } from '@/service'

const service = new systemService()
const rService = new roleService()
const sService = new systemInfoService()

export default {
  name: 'Home',
  components: {
    uploadAvatar,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        add: false,
        edit: false,
      },
      search: {
        search_type: 1,
        keyword: null,
        status: '',
        role_id: '',
      },
      step: 0,
      add: {
        username: null,
        valid_code: null,
        check: true,
        avatar: null,
        real_name: null,
        password: null,
        describe: null,
        role_id: null,
        status: true,
      },
      edit: {
        status: 0,
        role_id: [],
        master_id: null,
      },
      time: 60,
      timer: null,
      passwordType: 'password',
      codeLoading: false,
      finish: false,
      info: {},
      roleData: [],
      page: 1,
      pageSize: 20,
      data: [],
      total: 0,
      loading: false,
      typeData: [
        {
          name: '全部状态',
          id: '',
        },
        {
          name: '正常',
          id: '2',
        },
        {
          name: '禁用',
          id: '1',
        },
      ],
      roleSelectData: [],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '头像',
          scopedSlots: {
            customRender: 'avatar',
          },
        },
        { title: '账号姓名', dataIndex: 'real_name', customRender: text => text || '-' },
        { title: '绑定邮箱', dataIndex: 'email', customRender: text => text || '-' },
        { title: '绑定手机', dataIndex: 'telephone', customRender: text => text || '-' },
        { title: '所属角色', dataIndex: 'role', customRender: text => text.name },
        {
          title: '账号状态',
          dataIndex: 'status',
          customRender: text => (
            <bj-tag type={text === 1 ? 'primary' : 'danger'}>{text === 1 ? '正常' : '禁用'}</bj-tag>
          ),
        },
        { title: '添加时间', dataIndex: 'created_at' },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getList()
    this.getRole()
  },
  methods: {
    async getRole() {
      const { data } = await rService.getListDefault()
      const res = await rService.getListUser()
      this.roleData = [{ name: '全部角色', id: '' }].concat(data).concat(res.data)
      this.roleSelectData = data.concat(res.data)
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.accountList({
          page: this.page,
          page_size: this.pageSize,
          ...this.search,
        })
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    onAdd() {
      this.visible.add = true
    },
    onEdit(item) {
      this.visible.edit = true
      this.edit = {
        status: item.status,
        role_id: item.role_id,
        master_id: item.master_id,
      }
    },
    async editOk() {
      try {
        await rService.manageEdit(this.edit)
        this.$message.success('修改成功')
        this.getList()
        this.visible.edit = false
      } catch (e) {}
    },
    async onDelete(item) {
      try {
        await rService.manageDelete({
          master_id: item.id,
        })
        this.$message.success('删除成功')
        this.getList()
      } catch (error) {}
    },
    async onNext(_step) {
      if (_step === 1) {
        if (!this.add.username) {
          this.$message.error('请输入登录账号')
          return
        }
        const { data } = await rService.manageCheck({
          username: this.add.username,
        })
        if (data.success === 1) {
          this.add.check = false
          this.info = data
        } else {
          this.add.check = true
        }
        this.step = _step
      }
      if (_step === 2) {
        if (this.add.check) {
          const { data } = await rService.manageCreate(this.add)
          this.add.master_id = data.master_id
          this.step = 2
          this.add.status = 1
          this.add.role_id = []
          this.$message.success('创建成功')
        } else {
          this.add.master_id = this.info.master_id
          this.add.status = 1
          this.add.role_id = []
          this.step = 2
        }
      }
    },
    avatarChange(e) {
      this.add.avatar = e
    },
    passwordChange() {
      this.passwordType = this.passwordType === 'text' ? 'password' : 'text'
    },
    async onCreate() {
      try {
        await rService.manageAssociate({
          master_id: this.add.master_id,
          role_id: this.add.role_id,
          status: this.add.status,
        })
        this.$message.success('设置成功')
        this.visible.add = false
        this.initAdd()
        this.getList()
      } catch (e) {}
    },
    cancel() {
      this.visible.add = false
      this.initAdd()
      this.getList()
    },
    editCancel() {
      this.visible.edit = false
    },
    init() {
      this.search = {
        search_type: 1,
        keyword: null,
        status: '',
        role_id: '',
      }
      this.page = 1
      this.getList()
    },
    initAdd() {
      // 初始化添加信息
      this.add = {
        username: null,
        valid_code: null,
        check: true,
        avatar: null,
        real_name: null,
        password: null,
        describe: null,
        role_id: [],
        status: true,
      }
      this.time = 60
      this.timer = null
      this.step = 0
      this.info = {}
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    keydown(e) {
      if (e.keyCode == 32) {
        e.returnValue = false
      }
    },
    async getCode() {
      if (this.codeLoading) return
      try {
        this.codeLoading = true
        const { data } = await sService.getValidCode({
          template: 'code',
          username: this.add.username,
        })
        this.finish = true
        this.time = 60
        this.timer = setInterval(() => {
          if (this.time === 0) {
            this.finish = false
            clearInterval(this.timer)
          } else {
            this.time--
          }
        }, 1000)
        this.$message.success('带有验证码的信息已经通过你填写的邮箱或手机号码发送给你，注意查收。')
        this.valid_key = data
        setTimeout(() => {
          this.codeLoading = false
        })
      } catch (e) {
        setTimeout(() => {
          this.codeLoading = false
        })
      }
    },
  },
}
</script>

<style lang="less" module>
.home {
  :global {
    .ant-select-arrow {
      top: 40%;
    }
  }

  .input {
    :global {
      .ant-input-group-wrapper {
        position: relative;
        top: 4px;
      }

      .ant-select-selection__rendered {
        margin-left: 15px;
        color: #5c5c5c;
      }
    }
  }

  .table {
    min-height: calc(100vh - 240px);
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .avatar {
      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 100%;
      }
    }
  }

  .title {
    color: #000;
    font-size: 16px;
  }
}

.modal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .step {
    width: 400px;
    margin: 0 auto 20px;
  }

  .tip {
    width: 100%;
    background: #fafafa;
    color: #5c5c5c;
    padding: 10px;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .icon {
    color: @primary-color;
    font-size: 18px;
  }
}
</style>
